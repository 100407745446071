import { render, staticRenderFns } from "./TablePricelistItems.vue?vue&type=template&id=2091b46c&scoped=true&"
import script from "./TablePricelistItems.vue?vue&type=script&lang=js&"
export * from "./TablePricelistItems.vue?vue&type=script&lang=js&"
import style0 from "./TablePricelistItems.vue?vue&type=style&index=0&id=2091b46c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2091b46c",
  null
  
)

export default component.exports