<template>
  <div class="v-stack h-stretch">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>
              Are you sure you want to delete "{{
                selectedItem ? selectedItem.item.name : "null"
              }}" ?
            </div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="remove()" class="edit">Delete</button>
        </div>
      </div>
    </div>

    <div class="h-stack h-end gap-3" v-if="selectable">
      <button class="submit" @click="selectAll(true)">Select All</button>
      <button class="submit" @click="selectAll(false)">Select None</button>
    </div>

    <div v-for="mainCategory in items" :key="mainCategory.category">
      <div class="heading-title-1">{{ mainCategory.category }}</div>
      <div
        v-for="subCategory in mainCategory.items"
        :key="subCategory.category"
      >
        <div class="h-stack h-start gap-3">
          <div
            @click="subCategory.expand = !subCategory.expand"
            class="heading-title-2"
          >
            {{ subCategory.category }}
          </div>
          <div v-if="subCategory.expand">&#9660;</div>
          <div v-if="!subCategory.expand">&#9650;</div>
        </div>
        <div class="table py-5">
          <template v-if="subCategory.expand">
            <div
              class="table-header"
              v-bind:class="[
                showCount || editable ? 'item-row-count' : 'item-row',
              ]"
            >
              <div class="text-left">Name</div>
              <div class="text-left">Unit</div>
              <div class="text-right">Price</div>
              <div class="text-left" v-if="showCount || editable">Count</div>
              <div class="text-left" v-if="showCount || editable">
                Total Price
              </div>
              <div class="text-left">Comment</div>
            </div>
            <div
              @dragover.prevent
              @dragenter.prevent
              @drop.prevent="onDrop($event, item)"
              v-for="item in subCategory.items"
              :key="item._id"
              class="table-row"
              v-bind:class="[
                item.item.inHouse ? 'owned' : 'unowned',
                showCount || editable ? 'item-row-count' : 'item-row',
                editable && item.count > 0 ? 'pink' : '',
              ]"
              @click="onItemClick(item)"
            >
              <div class="h-stack h-start gap-3">
                <div
                  v-if="priceEditable"
                  :draggable="priceEditable"
                  @dragstart="onDrag($event, item)"
                >
                  <i class="fas fa-grip-lines"></i>
                </div>
                <div>
                  {{ item.item.name }}
                </div>
              </div>
              <div class="text-left">{{ item.item.unit }}</div>
              <div
                class="text-right"
                v-if="
                  (!priceEditable && !editable) ||
                  (editable && !item.item.editablePrice)
                "
              >
                {{ format.currency(item.price) }} Kč
              </div>
              <input
                type="number"
                v-model="item.price"
                v-if="priceEditable || (item.item.editablePrice && editable)"
              />
              <div class="text-left" v-if="showCount && !editable">
                {{ item.count }}
              </div>
              <input
                v-model="item.count"
                type="number"
                v-if="editable && item.item.editableCount"
              />
              <select
                v-model="item.count"
                v-if="editable && !item.item.editableCount"
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
              </select>
              <div class="text-left" v-if="showCount || editable">
                {{ format.currency(item.price * item.count) }} Kč
              </div>
              <div class="pane-right gap-3">
                <label class="text-left">{{ item.item.comment }}</label>
                <input
                  v-if="selectable"
                  v-model="item.selected"
                  type="checkbox"
                />
                <button
                  class="error cancel-button"
                  v-if="priceEditable"
                  @click="removeDialog(item)"
                >
                  X
                </button>
              </div>
            </div>
            <label class="pt-3">Count: {{ subCategory.items.length }}</label>
          </template>
        </div>
      </div>
    </div>
    <div v-if="items.length == 0">No items in the pricelist</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants.js";
import utils from "@/utils.js";

export default {
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    listItems: {
      type: Array,
      default: () => [],
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    priceEditable: {
      type: Boolean,
      default: false,
    },
    pushOnClick: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    listItems() {
      this.refresh();
    },
  },
  data() {
    return {
      items: [],
      units: {},
      selectedItem: null,
      format: utils.format,
    };
  },
  methods: {
    ...mapActions(["getPricelistItems"]),
    selectAll(all) {
      for (const mainCategory of this.items) {
        for (const subCategory of mainCategory.items) {
          for (const item of subCategory.items) {
            if (all) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          }
        }
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    onItemClick(item) {
      if (this.pushOnClick && !this.priceEditable) {
        this.$router.push("/pricelist/item/detail/" + item.item._id);
      }
    },
    onDrag(event, sourceItem) {
      event.dataTransfer.setData("item", sourceItem.item._id);
    },
    onDrop(event, targetItem) {
      const sourceItemId = event.dataTransfer.getData("item");
      const sourceItem = this.listItems.filter(
        (item) => item.item._id == sourceItemId
      )[0];

      this.$emit("onDragAndDrop", {
        sourceItem: sourceItem,
        targetItem: targetItem,
      });
    },
    refresh() {
      const items = this.listItems;
      this.items = [];

      const mainCategories = Array.from(
        new Set(items.map((item) => item.item.categoryMain))
      );

      for (const constantCategory of constants.pricelistMainCategories) {
        for (const category of mainCategories) {
          if (constantCategory == category) {
            const mainCategoryItems = [];

            const subCategories = new Set(
              items
                .filter((item) => item.item.categoryMain == category)
                .map((item) => item.item.categorySub)
            );

            const sub = [];

            for (const constantSubCategory of constants.pricelistSubCategoriesServices.concat(
              constants.pricelistSubCategoriesGear
            )) {
              for (const categorySub of subCategories) {
                if (constantSubCategory == categorySub) {
                  sub.push(categorySub);
                }
              }
            }

            for (const subCategory of sub) {
              mainCategoryItems.push({
                expand: true,
                category: subCategory,
                items: items
                  .filter((item) => item.item.categoryMain == category)
                  .filter((item) => item.item.categorySub == subCategory),
              });
            }

            this.items.push({
              category: category,
              items: mainCategoryItems,
            });
          }
        }
      }
    },
    removeDialog(item) {
      this.selectedItem = item;
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    remove() {
      this.$emit("deleteItem", this.selectedItem);
      this.dismiss();
    },
  },
  mounted() {
    for (const unit of constants.pricelistUnits) {
      this.units[unit.name] = unit.input;
    }

    this.refresh();
  },
};
</script>

<style scoped>
.item-row {
  grid-auto-columns: 1fr 100px 100px 1fr !important;
  gap: 10px;
}

.item-row-count {
  grid-auto-columns: 1fr 100px 100px 100px 100px 1fr !important;
  gap: 10px;
}

.owned {
  background-color: white;
}

.owned.pink {
  background-color: #ffe5f2;
}

.unowned {
  background-color: #e4e5e8;
}

.unowned.pink {
  background-color: #ff65b4;
}

.no-touch .table-row:hover {
  background-color: lightgoldenrodyellow;
}

.heading-title-2 {
  align-self: center;
  padding: 0px;
  user-select: none;
}

.heading-title-2:hover {
  text-decoration: underline;
}

select {
  width: 85px;
}

.cancel-button {
  text-align: center;
  padding: 0px;
  width: 41px !important;
  height: 41px !important;
  box-shadow: none;
  line-height: 0px;
  margin: 0;
  align-self: center;
  color: white;
}
</style>
